import { motion, useTransform, useViewportScroll } from 'framer-motion';
import React from 'react';
import GiftCardImage from '../../images/gift-card.png';
import BeansRight from '../atoms/BeansRight';
import Button from '../atoms/Button';
import CakeIcon from '../atoms/CakeIcon';
import SimpleHeroSection from '../molecules/SimpleHeroSection';

interface GiftCardsHeroSectionProps {
  heading: string;
  subheading: string;
  button1Label: string;
  button1Url: string;
  button2Label: string;
  button2Url: string;
}

const GiftCardsHeroSection = ({
  heading,
  subheading,
  button1Label,
  button1Url,
  button2Label,
  button2Url,
}: GiftCardsHeroSectionProps) => {
  const { scrollY } = useViewportScroll();
  const y = useTransform(scrollY, [0, 2000], [150, -100]);
  const y2 = useTransform(scrollY, [0, 2000], [20, -20]);
  const y3 = useTransform(scrollY, [0, 2000], [40, -60]);
  const y4 = useTransform(scrollY, [0, 2000], [60, -190]);
  const y5 = useTransform(scrollY, [0, 2000], [50, -100]);

  return (
    <div className="relative overflow-hidden pb-60">
      <div className="absolute right-0 bottom-32">
        <BeansRight className="-z-1" style={{ y }} />
      </div>

      <SimpleHeroSection>
        <div className="container relative pb-20 text-center md:pb-40">
          <h1 className="mb-4 u-h2">{heading}</h1>
          <h2 className="mb-6 u-p">{subheading}</h2>
          <div className="flex flex-col items-center justify-center space-y-6 md:flex-row md:space-y-0 md:space-x-6">
            <Button to={button1Url} variant="default">
              {button1Label}
            </Button>
            <Button to={button2Url} variant="default">
              {button2Label}
            </Button>
          </div>

          <div className="absolute left-0 right-0 z-10 flex justify-center -bottom-56">
            <CakeIcon
              className="absolute hidden md:block bottom-24 -ml-80 left-1/2 -z-1"
              style={{ y: y2 }}
            />
            <motion.svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="36"
              fill="none"
              viewBox="0 0 34 36"
              className="absolute top-0 -ml-52 md:-ml-80 left-1/2 z-1"
              style={{ y: y3 }}
            >
              <path
                fill="#434867"
                d="M32.98 18.172c-4.352-3.438-10.077-5.386-12.368-10.886-.572-1.49-.916-6.074-2.175-6.99-1.833-1.261-2.405 1.833-2.863 3.322-1.489 5.845-3.55 10.085-9.848 11.804C4.58 15.766 0 15.652 0 17.026c0 1.605 4.81 1.605 6.184 2.063 3.55 1.26 6.412 3.667 7.787 7.22.801 1.948.916 4.01 1.145 6.073.114 1.146.343 5.157 2.06 2.292.803-1.375.574-4.698 1.375-6.303a14.545 14.545 0 013.665-5.386c1.717-1.604 3.893-2.75 6.183-3.094.115-.344 6.871 0 4.58-1.719z"
              ></path>
            </motion.svg>
            <motion.svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="9"
              fill="none"
              viewBox="0 0 9 9"
              className="absolute -ml-44 md:-ml-56 top-12 left-1/2 -z-1"
              style={{ y: y3 }}
            >
              <path
                fill="#E7C6C7"
                d="M4.44.223c-5.267 0-5.267 8.136 0 8.136 5.268 0 5.268-8.136 0-8.136z"
              ></path>
            </motion.svg>
            <motion.svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="9"
              fill="none"
              viewBox="0 0 8 9"
              className="absolute ml-56 top-12 left-1/2 -z-1"
              style={{ y: y3 }}
            >
              <path
                fill="#434867"
                d="M3.95 0c-5.267 0-5.267 8.136 0 8.136C9.219 8.136 9.219 0 3.95 0z"
              ></path>
            </motion.svg>
            <motion.svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="41"
              fill="none"
              viewBox="0 0 36 41"
              className="absolute ml-64 top-24 left-1/2 -z-1"
              style={{ y: y4 }}
            >
              <path
                fill="#E7C6C7"
                d="M34.818 18.036c-2.52-2.292-6.527-1.948-9.62-3.209-5.725-2.521-5.152-7.563-6.526-12.72-.573-2.292-1.833-3.094-2.749-.459-.343 1.032.23 2.292.23 3.324-.115 1.719-.688 3.323-1.604 4.813-1.603 2.636-4.122 4.584-6.87 5.844-1.49.688-7.444 1.26-7.673 2.75-.229 1.834 6.184 3.324 7.443 4.011 2.978 1.605 5.497 3.668 6.871 6.876 1.26 2.865 0 7.45 1.374 9.97.458.802 1.26 2.178 2.405.802.916-1.146-.114-4.24 0-5.615.114-1.26.343-2.75.802-4.01.801-2.407 2.175-4.355 4.122-5.845 1.832-1.375 4.008-2.178 6.184-2.865 1.03-.344 7.901-1.604 5.61-3.667z"
              ></path>
            </motion.svg>
            <motion.svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="36"
              fill="none"
              viewBox="0 0 34 36"
              className="absolute ml-36 md:ml-52 top-20 md:top-48 left-1/2 -z-1"
              style={{ y: y5 }}
            >
              <path
                fill="#434867"
                d="M32.98 18.172c-4.352-3.438-10.077-5.386-12.368-10.886-.572-1.49-.916-6.074-2.175-6.99-1.833-1.261-2.405 1.833-2.863 3.322-1.489 5.845-3.55 10.085-9.848 11.804C4.58 15.766 0 15.652 0 17.026c0 1.605 4.81 1.605 6.184 2.063 3.55 1.26 6.412 3.667 7.787 7.22.801 1.948.916 4.01 1.145 6.073.114 1.146.343 5.157 2.06 2.292.803-1.375.574-4.698 1.375-6.303a14.545 14.545 0 013.665-5.386c1.717-1.604 3.893-2.75 6.183-3.094.115-.344 6.871 0 4.58-1.719z"
              ></path>
            </motion.svg>
            <img
              src={GiftCardImage}
              alt="The Salty Donut Gift Card"
              className="w-[360px] md:w-[442px] h-auto"
            />
          </div>
        </div>
      </SimpleHeroSection>
    </div>
  );
};

export default GiftCardsHeroSection;
