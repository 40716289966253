import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import GiftCardsHeroSection from '../components/organisms/GiftCardsHeroSection';
import NewsletterSection from '../components/organisms/NewsletterSection';
import { PromoPopup } from '../components/organisms/PromoPopup';

const GiftCards = ({
  data: {
    page,
    wp: {
      popupSettings: { popupSettings },
    },
  },
}) => {
  return (
    <>
      <Layout>
        <Seo post={page} />

        <GiftCardsHeroSection {...page.giftCards.giftCardsHero} />
        <NewsletterSection />
      </Layout>
      <PromoPopup {...popupSettings} />
    </>
  );
};

export const pageQuery = graphql`
  query GiftCardsPageQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      ...SEO
      giftCards {
        giftCardsHero {
          heading
          subheading
          button1Label
          button1Url
          button2Label
          button2Url
        }
      }
    }
    wp {
      popupSettings {
        popupSettings {
          popupEnabled
          image {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1024, placeholder: BLURRED)
              }
            }
          }
          url
        }
      }
    }
  }
`;

export default GiftCards;
